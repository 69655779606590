var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProfileSettingsOverlayStyled', [_c('HeaderStyled', [_vm._v(" " + _vm._s(_vm.$tc('profile', 1)) + " ")]), _c('MainStyled', [_c('label', [_vm._v(_vm._s(_vm.$tc('name', 1)))]), _c('input', {
    attrs: {
      "id": "sensitive",
      "disabled": true
    },
    domProps: {
      "value": _vm.profile.name
    }
  }), _c('label', [_vm._v(_vm._s(_vm.$tc('email', 1)))]), _c('input', {
    attrs: {
      "id": "sensitive",
      "disabled": true
    },
    domProps: {
      "value": _vm.profile.email
    }
  }), _c('label', [_vm._v(_vm._s(_vm.$tc('picture', 1)))]), _c('ImageUploader', {
    attrs: {
      "single": true,
      "image": _vm.profile.picture
    },
    on: {
      "uploaded": _vm.handleUpload,
      "disconnect": _vm.handleDisconnect
    }
  }), _vm.organizations.length ? _c('OrganizationsHeaderStyled', [_vm._v(" " + _vm._s(_vm.$t('titles.yourOrganizations')) + " ")]) : _vm._e(), _vm._l(_vm.organizations, function (organization) {
    return _c('OrganizationSettings', {
      key: organization.id,
      attrs: {
        "organization": organization
      }
    });
  })], 2), _c('FooterStyled')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }